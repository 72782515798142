












































import { Vue, Component } from 'vue-property-decorator';
import Workspaces from "@/state/Workspaces";
import SalesTaxService from "@/services/SalesTaxService";
import SalesTaxPermitDTO from "@/dto/salestax/SalesTaxPermitDTO";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import CreateOrUpdateSalesTaxPermitRequestDTO from "@/dto/salestax/CreateOrUpdateSalesTaxPermitRequestDTO";
import FileUpload from "@/components/common/FileUpload.vue";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import {SalesTaxSubscriptionDTO} from "@/dto/salestax/SalesTaxSubscriptionDTO";
import SalesTaxSubscription from "@/components/salestax/SalesTaxSubscription.vue";
import RouteNames from "@/router/RouteNames";

const AppModule = namespace("App");

@Component({
  components: {SalesTaxSubscription}
})
export default class SalesTaxSubscriptionList extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  private message = "";
  private successful = true;

  private subscriptions: Array<SalesTaxSubscriptionDTO> = [];

  private addMode = false;

  private newPermitRequest = new CreateOrUpdateSalesTaxPermitRequestDTO();

  mounted(){
    this.message = "";
    this.loadSubscriptions();
  }

  loadSubscriptions(){
    this.startLoading();
    return SalesTaxService.getSalesTaxSubscriptions(Workspaces.getCurrent!.id).then(
        response => {
          this.subscriptions = response.data;
          this.stopLoading();
        },
        error => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading();
        }
    );
  }

  onSubscriptionAdded(){
    this.addMode = false;
    this.loadSubscriptions();
  }

  goToSubscription(s: SalesTaxSubscriptionDTO){
    this.$router.push({
      name: RouteNames.SALES_TAX_SUBSCRIPTION,
      params: {
        "companyId": `${Workspaces.getCurrent?.id}`,
        "subscriptionId": `${s.id}`
      }
    });
  }

}
